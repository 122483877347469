/* @vue/component */

<template>
    <v-container class="map-container" fluid fill-height>
        <div class="floating-ad">
            <AdBTWR dark v-if="!user.isAdFree"/>
        </div>

        <div id="map"></div>

        <v-btn dark color="almostblack" id="map-options-btn" @click="dialogs.options.state = true">
            <v-icon>mdi-cog</v-icon>
        </v-btn>

        <MapPopupContent
            :properties="popupProperties"
            ref="popup-content"
            v-if="showPopup"
            @view-details="navigateToRepeater"
        />

        <v-dialog
            v-model="dialogs.options.state"
            :fullscreen="$vuetify.breakpoint.smAndDown"
            content-class="repeater-dialog"
            attach=".map-container"
            width="400"
        >
            <v-card>
                <v-toolbar color="rfaccent2" dense dark>
                    <v-toolbar-title>Map Options</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-icon size="18" @click="dialogs.options.state = false">mdi-close</v-icon>
                </v-toolbar>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" class="pb-0">
                                <v-select
                                    v-model="mapStyle"
                                    label="Map Style"
                                    :items="mapStyles"
                                    outlined
                                    dense
                                    hide-details
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="px-4 py-0">
                                <v-switch
                                    v-model="options.showOffline"
                                    label="Show Offline Repeaters"
                                    color="primary"
                                    hide-details
                                    inset
                                    dense
                                    xclass="mb-2"
                                ></v-switch>
                            </v-col>
                            <v-col cols="12" class="px-4 py-0">
                                <v-switch
                                    v-model="options.showOutdated"
                                    label="Show Stale Repeaters"
                                    color="primary"
                                    hide-details
                                    inset
                                    dense
                                    xclass="mb-2"
                                ></v-switch>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-overlay
            absolute
            color="black"
            :value="dialogs.loading.state"
            class="text-center"
        >
            <LoadingAnimation/>
        </v-overlay>
    </v-container>
</template>

<style lang="scss">
    @import '~vuetify/src/styles/styles.sass';

    #map {
        width: 100%;
        height: 100%;
    }

    .map-container {
        padding: 0;
    }

    #map-options-btn {
        position: absolute;
        top: 20px;
        left: 10px;
        z-index: 5;
    }

    #map-options {
        position: absolute;
        top: 90px;
        left: 10px;
        width: 250px;
        z-index: 6;
    }

    .width-100 {
        width: 100%;
    }

    .mw-100 {
        max-width: 100%;
    }

    .repeater-dialog {
        margin-top: 64px;
        z-index: 1002;
    }
</style>

<script>
    import AdBTWR from '@/components/ads/BTWR.vue';
    import MapPopupContent from '@/components/MapPopupContent.vue';
    import LoadingAnimation from '@/components/LoadingAnimation.vue';
    import mapboxgl from 'mapbox-gl';
    import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
    import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
    import axios from 'axios';
    import config from '../config';
    import { useTitle } from '@vueuse/core';

    let map;

    export default {
        name: "MapboxGL",

        props: ['user'],

        components: {AdBTWR, MapPopupContent, LoadingAnimation},

        data: () => ({
            dialogs: {
                options: {
                    state: false
                },
                loading: {
                    state: false
                }
            },
            options: {
                showOutdated: false,
                showOffline: false
            },
            popupProperties: {},
            geoJsonSource: {
                type: 'geojson',
                cluster: true,
                clusterMaxZoom: 14,
                clusterRadius: 50,
                data: {}
            },
            geoJsonSourceCoverage: {
                type: 'geojson',
                cluster: false,
                clusterMaxZoom: 14,
                clusterRadius: 50,
                data: {}
            },
            showPopup: false,
            mapStyle: localStorage.getItem('mapStyle') || 'satellite-streets-v10',
            mapStyles: [
                {value: 'streets-v11', text: 'Streets (Color)'},
                {value: 'light-v10', text: 'Streets (Light)'},
                {value: 'dark-v10', text: 'Streets (Dark)'},
                {value: 'outdoors-v11', text: 'Outdoors'},
                {value: 'satellite-v9', text: 'Satellite'},
                {value: 'satellite-streets-v10', text: 'Satellite Streets'},
            ],
            userLocation: null,
            initialLoadComplete: false,
            loadingAllRepeaters: false,
            searchRadius: 100, // Search radius in miles
            popupFromUrl: false,
            locationResponseReceived: false,
            lastKnownPosition: null,
            mapLoaded: false,
            styleLoaded: false,  // Add new property to track style loading
        }),

        watch: {
            // "$vuetify.theme.dark"() {
            //     this.initMap();
            //
            //     this.fetchLocalRepeaters();
            // },

            options: {
                handler() {
                    this.fetchLocalRepeaters();
                },
                deep: true
            },

            mapStyle(newStyle) {
                localStorage.setItem('mapStyle', newStyle);
                this.switchStyle(newStyle);
            },

            geoJsonSource: {
                handler() {
                    //if a repeater ID is passed in the route params, flyTo that repeater
                    if (this.$route.params.id && !this.popupFromUrl) {
                        this.popupFromUrl = true;
                        let found = this.$_.find(this.geoJsonSource.data.features, {properties: {id: parseInt(this.$route.params.id)}});
                        if (found) {
                            map.flyTo({
                                center: found.geometry.coordinates,
                                zoom: 11
                            });

                            if(found.properties.radius === 'null') found.properties.radius = null;
                            if(found.properties.haat === 'null') found.properties.haat = null;

                            // Set the coverage circle state before opening popup
                            map.setFeatureState(
                                {source: 'repeater-coverage', id: found.properties.id},
                                {selected: true}
                            );

                            this.openRepeaterPopup(map, found.geometry.coordinates, found.properties);
                        }
                    }
                },
                deep: true
            }
        },

        methods: {
            initMap() {
                mapboxgl.accessToken = config.MAPBOX_ACCESS_TOKEN;
                
                // Get last known position from localStorage
                const savedPosition = localStorage.getItem('lastMapPosition');
                const initialPosition = savedPosition ? JSON.parse(savedPosition) : {
                    center: [-100, 37],
                    zoom: 4
                };

                // Preload the style with proper URL format
                const styleUrl = `https://api.mapbox.com/styles/v1/mapbox/${this.mapStyle}?access_token=${mapboxgl.accessToken}`;
                fetch(styleUrl)
                    .then(response => response.json())
                    .then(style => {
                        // Initialize map with the preloaded style
                        map = new mapboxgl.Map({
                            container: 'map',
                            style: style,
                            center: initialPosition.center,
                            zoom: initialPosition.zoom
                        });

                        // Set up map event handlers
                        map.once('style.load', () => {                            
                            // Add controls
                            if (this.$vuetify.breakpoint.smAndUp) {
                                map.addControl(
                                    new MapboxGeocoder({
                                        accessToken: mapboxgl.accessToken,
                                        mapboxgl: mapboxgl
                                    }), 'bottom-left'
                                );
                            }
                            map.addControl(new mapboxgl.FullscreenControl());
                            map.addControl(new mapboxgl.NavigationControl());

                            // Add the geolocation control but don't trigger it automatically
                            const geolocateControl = new mapboxgl.GeolocateControl({
                                positionOptions: {
                                    enableHighAccuracy: true
                                },
                                trackUserLocation: true
                            });
                            map.addControl(geolocateControl);

                            // Listen for manual geolocation control usage
                            geolocateControl.on('geolocate', (e) => {
                                const newLocation = {
                                    lat: e.coords.latitude,
                                    lng: e.coords.longitude
                                };
                                
                                // Update stored location
                                this.userLocation = newLocation;
                                this.lastKnownPosition = newLocation;
                                localStorage.setItem('lastKnownLocation', JSON.stringify(newLocation));
                                
                                // No need to fly to location since the control handles that
                                this.fetchLocalRepeaters();
                            });

                            // Add click handler for repeaters
                            map.on('click', 'repeaters', e => {
                                //keep track of existing repeater ID before we modify it
                                const currentId = this.$route.params.id;

                                // Update URL to include repeater ID
                                if (this.$route.params.id !== e.features[0].properties.id.toString()) {
                                    this.$router.push({
                                        name: 'map-id',
                                        params: {id: e.features[0].properties.id}
                                    });
                                }

                                //deselect existing coverage polygon
                                if (currentId) {
                                    map.setFeatureState(
                                        {source: 'repeater-coverage', id: currentId},
                                        {selected: false}
                                    );
                                }

                                map.flyTo({
                                    center: e.features[0].geometry.coordinates,
                                    zoom: 11
                                });

                                if(e.features[0].properties.radius === 'null') e.features[0].properties.radius = null;
                                if(e.features[0].properties.haat === 'null') e.features[0].properties.haat = null;
                                
                                this.openRepeaterPopup(map, e.features[0].geometry.coordinates, e.features[0].properties);
                            });

                            // Add click handler for clusters
                            map.on('click', 'repeater-cluster', e => {
                                let features = map.queryRenderedFeatures(e.point, {
                                    layers: ['repeater-cluster']
                                });
                                let clusterId = features[0].properties.cluster_id;
                                map.getSource('repeater-data').getClusterExpansionZoom(
                                    clusterId,
                                    function (err, zoom) {
                                        if (err) return;

                                        map.easeTo({
                                            center: features[0].geometry.coordinates,
                                            zoom: zoom
                                        });
                                    }
                                );
                            });

                            // Add hover handlers
                            let hoveredStateId = null;
                            map.on('mouseenter', 'repeaters', (e) => {
                                map.getCanvas().style.cursor = 'pointer';

                                if (e.features.length > 0) {
                                    if (hoveredStateId) {
                                        map.setFeatureState(
                                            {source: 'repeater-coverage', id: hoveredStateId},
                                            {hover: false}
                                        );
                                    }
                                    hoveredStateId = e.features[0].id;
                                    map.setFeatureState(
                                        {source: 'repeater-coverage', id: hoveredStateId},
                                        {hover: true}
                                    );
                                }
                            });

                            map.on('mouseleave', 'repeaters', () => {
                                map.getCanvas().style.cursor = '';

                                if (hoveredStateId) {
                                    map.setFeatureState(
                                        {source: 'repeater-coverage', id: hoveredStateId},
                                        {hover: false}
                                    );
                                }
                                hoveredStateId = null;
                            });

                            map.on('mouseenter', 'repeater-cluster', () => {
                                map.getCanvas().style.cursor = 'pointer';
                            });

                            map.on('mouseleave', 'repeater-cluster', () => {
                                map.getCanvas().style.cursor = '';
                            });

                            // Continue with initialization
                            if (this.$route.params.id) {
                                // Handle repeater ID case
                                this.locationResponseReceived = true;
                                this.dialogs.loading.state = true;
                                this.fetchSingleRepeater(this.$route.params.id)
                                    .then(repeater => {
                                        // Create initial map data with just this repeater
                                        const singleRepeaterData = {
                                            id: repeater.ID,
                                            title: repeater.Name || `${repeater.Location} ${repeater.Frequency.replace('462.', '')}`,
                                            location: repeater.Location,
                                            state: repeater.State,
                                            type: repeater.Type,
                                            owner: repeater.Owner,
                                            frequency: repeater.Frequency.replace('46X', '462'),
                                            toneOut: repeater['PL Out'],
                                            toneIn: repeater['PL In'],
                                            status: repeater.Status === 'Online',
                                            haat: repeater.HAAT,
                                            radius: repeater.Radius,
                                            node: repeater.Node ? repeater.Node.toString() : null,
                                            description: repeater.Rules,
                                            modified: repeater.Modified,
                                            Longitude: repeater.Longitude,
                                            Latitude: repeater.Latitude
                                        };

                                        // Update map with just this repeater
                                        this.updateMapData([singleRepeaterData]);
                                        
                                        // Jump to the repeater location without animation
                                        map.jumpTo({
                                            center: [repeater.Longitude, repeater.Latitude],
                                            zoom: 11
                                        });

                                        // Hide loading dialog after single repeater is shown
                                        this.dialogs.loading.state = false;

                                        // Load all repeaters silently in background
                                        this.loadAllRepeaters();
                                    })
                                    .catch(err => {
                                        console.error('Error fetching repeater:', err);
                                        if (err.response?.status === 401) {
                                            this.$emit('unauthorized');
                                        }
                                        this.dialogs.loading.state = false;
                                    });
                            } else {
                                // Handle normal case
                                const savedLocation = localStorage.getItem('lastKnownLocation');
                                if (savedLocation) {
                                    // ... handle saved location
                                }
                                this.getCurrentLocation();
                            }

                            // Show loading state at start
                            this.dialogs.loading.state = true;

                            this.styleLoaded = true;
                            this.checkLoadingComplete();  // Check if we can hide loading
                        });
                    })
                    .catch(error => {
                        console.error('Error loading map style:', error);
                        // Fallback to normal initialization if preload fails
                        this.initMapFallback(initialPosition);
                    });
            },

            // Fallback method if style preload fails
            initMapFallback(initialPosition) {
                map = new mapboxgl.Map({
                    container: 'map',
                    style: 'mapbox://styles/mapbox/' + this.mapStyle,
                    center: initialPosition.center,
                    zoom: initialPosition.zoom
                });

                // ... rest of normal initialization ...
            },

            getCurrentLocation() {
                if ('geolocation' in navigator) {
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            this.locationResponseReceived = true;
                            const newLocation = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude
                            };
                            
                            // Compare with lastKnownPosition instead of userLocation
                            const hasLocationChanged = !this.lastKnownPosition || 
                                Math.abs(this.lastKnownPosition.lat - newLocation.lat) > 0.01 || 
                                Math.abs(this.lastKnownPosition.lng - newLocation.lng) > 0.01;
                            
                            if (hasLocationChanged) {                                
                                this.userLocation = newLocation;
                                this.lastKnownPosition = newLocation;
                                localStorage.setItem('lastKnownLocation', JSON.stringify(newLocation));
                                
                                // Only fly to user location if there's no repeater ID in URL
                                if (!this.$route.params.id) {
                                    map.flyTo({
                                        center: [position.coords.longitude, position.coords.latitude],
                                        zoom: 10
                                    });
                                    
                                    // First load local repeaters
                                    this.fetchLocalRepeaters().then(() => {
                                        // Then load all repeaters
                                        return this.loadAllRepeaters();
                                    }).then(() => {
                                        this.initialLoadComplete = true;
                                        this.checkLoadingComplete();
                                    }).catch(err => {
                                        console.error('Error loading repeaters:', err);
                                        this.dialogs.loading.state = false;
                                    });
                                }
                            }
                        },
                        (error) => {
                            console.error('Geolocation error:', error);
                            this.locationResponseReceived = true;
                            // Location access was denied or error occurred
                            this.userLocation = {
                                lat: 39.828347,
                                lng: -98.579481
                            };
                            
                            // Load all repeaters immediately instead of local ones
                            this.loadAllRepeaters().then(() => {
                                // After loading all repeaters, set initialLoadComplete
                                this.initialLoadComplete = true;
                                this.checkLoadingComplete();
                            }).catch(err => {
                                console.error('Error loading all repeaters:', err);
                                this.dialogs.loading.state = false;
                            });
                        },
                        {
                            enableHighAccuracy: true,
                            timeout: 5000,
                            maximumAge: 0
                        }
                    );
                } else {
                    this.locationResponseReceived = true;
                    // Fallback to default location
                    this.userLocation = {
                        lat: 39.828347,
                        lng: -98.579481
                    };
                    
                    // Load all repeaters immediately instead of local ones
                    this.loadAllRepeaters().then(() => {
                        // After loading all repeaters, set initialLoadComplete
                        this.initialLoadComplete = true;
                        this.checkLoadingComplete();
                    }).catch(err => {
                        console.error('Error loading all repeaters:', err);
                        this.dialogs.loading.state = false;
                    });
                }
            },

            removeExistingSources() {
                // Only try to remove if map is defined
                if (!map) return;

                try {
                    // Remove layers first (must be done before removing sources)
                    const layersToRemove = ['repeaters', 'repeater-cluster', 'count-layer', 'repeater-coverage'];
                    layersToRemove.forEach(layer => {
                        if (map.getLayer(layer)) {
                            map.removeLayer(layer);
                        }
                    });

                    // Remove sources
                    const sourcesToRemove = ['repeater-data', 'repeater-coverage'];
                    sourcesToRemove.forEach(source => {
                        if (map.getSource(source)) {
                            map.removeSource(source);
                        }
                    });

                    // Remove image
                    if (map.hasImage('mygmrslogo')) {
                        map.removeImage('mygmrslogo');
                    }
                } catch (err) {
                    console.error('Error removing map sources:', err);
                }
            },

            addSources() {
                this.removeExistingSources();

                let layers = map.getStyle().layers;
                // find the index of the first symbol layer in the map style
                let firstSymbolId;
                for (let i = 0; i < layers.length; i++) {
                    if (layers[i].type === 'symbol') {
                        firstSymbolId = layers[i].id;
                        break;
                    }
                }

                map.addSource('repeater-data', this.geoJsonSource);
                map.addSource('repeater-coverage', this.geoJsonSourceCoverage);

                if (!map.hasImage('mygmrslogo')) {
                    map.loadImage('/images/favicon32.png', (err, image) => {
                        if (err) {
                            console.error('Error loading image:', err);
                            return;
                        }

                        try {
                            map.addImage('mygmrslogo', image);
                            this.addMapLayersWithImage(firstSymbolId);
                        } catch (err) {
                            console.error('Error adding image:', err);
                        }
                    });
                } else {
                    this.addMapLayersWithImage(firstSymbolId);
                }
            },

            addMapLayersWithImage(firstSymbolId) {
                try {
                    if (!map.getLayer('repeater-cluster')) {
                        map.addLayer({
                            id: "repeater-cluster",
                            source: "repeater-data",
                            type: "circle",
                            paint: {
                                "circle-color": [
                                    "step",
                                    ["get", "point_count"],
                                    this.$vuetify.theme.themes.dark.rfaccent,
                                    50,
                                    this.$vuetify.theme.themes.dark.rforange,
                                    100,
                                    this.$vuetify.theme.themes.dark.error,
                                ],
                                "circle-radius": [
                                    "step",
                                    ["get", "point_count"],
                                    20,
                                    100,
                                    30,
                                    750,
                                    40,
                                ],
                            },
                            filter: ["has", "point_count"],
                        });
                    }

                    if (!map.getLayer('repeaters')) {
                        map.addLayer({
                            id: "repeaters",
                            source: "repeater-data",
                            type: "symbol",
                            paint: {
                                "text-color": "#fff",
                                "text-halo-color": "#222",
                                "text-halo-width": 2,
                                "text-halo-blur": 1
                            },
                            layout: {
                                "icon-image": "mygmrslogo",
                                "icon-size": 0.75,
                                "text-field": [
                                    "format",
                                    ["get", "title"], {"font-scale": 0.9},
                                    "\n", {},
                                    ["concat", "462.", ["slice", ["get", "frequency"], 4, 7]], {"font-scale": 0.8},
                                    " ", {},
                                    ["get", "toneIn"], {"font-scale": 0.8}
                                ],
                                "text-font": ["Roboto Regular"],
                                "text-size": 12,
                                "text-offset": [0, 1.5],
                                "text-anchor": "top",
                                "text-justify": "center",
                                "text-max-width": 12,
                                "text-allow-overlap": false,
                                "icon-allow-overlap": true
                            },
                            filter: ["!", ["has", "point_count"]],
                        });
                    }

                    if (!map.getLayer('count-layer')) {
                        map.addLayer({
                            id: "count-layer",
                            source: "repeater-data",
                            type: "symbol",
                            paint: {
                                "text-color": "#ffffff"
                            },
                            layout: {
                                "text-field": "{point_count_abbreviated}",
                                "text-font": ["Roboto Regular"],
                                "text-size": 16
                            },
                            filter: ["has", "point_count"]
                        });
                    }

                    if (!map.getLayer('repeater-coverage')) {
                        map.addLayer({
                            id: "repeater-coverage",
                            source: "repeater-coverage",
                            type: "fill",
                            paint: {
                                "fill-color": this.$vuetify.theme.themes.dark.success,
                                "fill-opacity": [
                                    "case",
                                    ["boolean", ["feature-state", "hover"], false],
                                    0.5,
                                    ["boolean", ["feature-state", "selected"], false],
                                    0.5,
                                    0
                                ]
                            },
                        }, firstSymbolId);
                    }
                } catch (err) {
                    console.error('Error adding map layers:', err);
                }
            },

            switchStyle(layerId) {
                map.setStyle('mapbox://styles/mapbox/' + layerId);

                this.fetchLocalRepeaters();
            },

            openRepeaterPopup(mapInstance, coordinates, properties) {
                // Close any existing popups
                const existingPopups = document.getElementsByClassName('mapboxgl-popup');
                if (existingPopups.length) {
                    Array.from(existingPopups).forEach(popup => popup.remove());
                }

                this.popupProperties = properties;
                this.showPopup = true;

                this.$nextTick(() => {
                    new mapboxgl.Popup()
                        .setLngLat(coordinates)
                        .setDOMContent(this.$refs['popup-content'].$el)
                        .on('open', () => {
                            map.setFeatureState(
                                {source: 'repeater-coverage', id: properties.id},
                                {selected: true}
                            );
                        })
                        .on('close', () => {
                            if (properties.id) {
                                map.setFeatureState(
                                    {source: 'repeater-coverage', id: properties.id},
                                    {selected: false}
                                );
                                
                                // Remove repeater ID from URL when popup closes
                                if (this.$route.params.id) {
                                    this.$router.push({
                                        name: 'map'
                                    });
                                }
                                this.popupFromUrl = false;
                            }
                        })
                        .addTo(map);
                });
            },

            createGeoJSONCircle(center, radiusInKm, points) {
                if (!points) points = 64;

                let coords = {
                    latitude: center[1],
                    longitude: center[0]
                };

                let km = radiusInKm;

                let ret = [];
                let distanceX = km / (111.320 * Math.cos(coords.latitude * Math.PI / 180));
                let distanceY = km / 110.574;

                let theta, x, y;
                for (let i = 0; i < points; i++) {
                    theta = (i / points) * (2 * Math.PI);
                    x = distanceX * Math.cos(theta);
                    y = distanceY * Math.sin(theta);

                    ret.push([coords.longitude + x, coords.latitude + y]);
                }
                ret.push(ret[0]);

                return ret;
            },

            range(radius, haat) {
                if (!radius) {
                    const distRepeater = ((4.12 * Math.sqrt(haat / 3.28084)));
                    const distRadio = ((4.12 * Math.sqrt(5 / 3.28084)));
                    return Math.round((distRadio + distRepeater) * 10) / 10
                } else {
                    return radius;
                }
            },

            async fetchLocalRepeaters() {
                try {
                    if (this.userLocation) {
                        const repeaters = await this.fetchRepeaterData({
                            location: this.userLocation,
                            radius: this.searchRadius
                        });
                        
                        this.updateMapData(repeaters);
                        this.initialLoadComplete = true;
                        this.checkLoadingComplete();  // Check if we can hide loading
                        
                        if (this.mapLoaded) {
                            this.loadAllRepeaters();
                        }
                    } else {
                        const allRepeaters = await this.fetchRepeaterData();
                        this.updateMapData(allRepeaters);
                        this.initialLoadComplete = true;
                        this.checkLoadingComplete();  // Check if we can hide loading
                    }
                } catch (err) {
                    console.error('Error fetching repeaters:', err);
                    if (err.response?.status === 401) {
                        this.$emit('unauthorized');
                    }
                    this.dialogs.loading.state = false;  // Still hide on error
                }
            },

            async fetchRepeaterData({ location = null, radius = null } = {}) {
                let url = `${config.API_LOCATION}/repeaters?limit=99999&outdated=${this.options.showOutdated}&offline=${this.options.showOffline}&description=false`;
                
                if (location && radius) {
                    url += `&latitude=${location.lat}&longitude=${location.lng}&range=${radius}`;
                }

                const headers = {};
                if (this.user.authToken) headers.Authorization = `Bearer ${this.user.authToken}`;

                const response = await axios.get(url, { headers });
                return response.data.items;
            },

            updateMapData(repeaters) {
                // Store current selected repeater ID if any
                const selectedRepeaterId = this.$route.params.id ? parseInt(this.$route.params.id) : null;

                // Get current features if they exist
                const currentFeatures = this.geoJsonSource.data.features || [];
                const currentFeatureIds = new Set(currentFeatures.map(f => f.id));

                const geoJson = {
                    type: "FeatureCollection",
                    features: []
                };

                const geoJsonCoverage = {
                    type: "FeatureCollection", 
                    features: []
                };

                // Process repeaters into features
                const repeatersArray = Array.isArray(repeaters) ? repeaters : [repeaters];
                
                repeatersArray.forEach(o => {
                    // Handle both single repeater and list repeater formats
                    const repeater = {
                        ID: o.ID || o.id,
                        Frequency: (o.Frequency || o.frequency || '').replace('46X', '462'),
                        Name: o.Name || o.name,
                        Location: o.Location || o.location,
                        State: o.State || o.state,
                        Type: o.Type || o.type,
                        Owner: o.Owner || o.owner,
                        'PL Out': o['PL Out'] || o.toneOut,
                        'PL In': o['PL In'] || o.toneIn,
                        Status: o.Status || o.status,
                        HAAT: o.HAAT || o.haat,
                        Radius: o.Radius || o.radius,
                        Node: o.Node || o.node,
                        Rules: o.Rules || o.description,
                        Modified: o.Modified || o.modified,
                        Longitude: o.Longitude || o.longitude,
                        Latitude: o.Latitude || o.latitude
                    };

                    if (!repeater.Name || this.$_.trim(repeater.Name).length === 0) {
                        repeater.Name = `${repeater.Location} ${repeater.Frequency.replace('462.', '')}`;
                    }

                    // If this repeater is already on the map, keep the existing feature
                    if (currentFeatureIds.has(repeater.ID)) {
                        const existingFeature = currentFeatures.find(f => f.id === repeater.ID);
                        const existingCoverage = this.geoJsonSourceCoverage.data.features.find(f => f.id === repeater.ID);
                        
                        geoJson.features.push(existingFeature);
                        geoJsonCoverage.features.push(existingCoverage);
                        return;
                    }

                    // Create new feature for new repeater
                    const properties = {
                        id: repeater.ID,
                        title: repeater.Name,
                        location: repeater.Location,
                        state: repeater.State,
                        type: repeater.Type,
                        owner: repeater.Owner,
                        frequency: repeater.Frequency,
                        toneOut: repeater['PL Out'],
                        toneIn: repeater['PL In'],
                        status: repeater.Status === 'Online',
                        haat: repeater.HAAT,
                        radius: repeater.Radius,
                        node: repeater.Node ? repeater.Node.toString() : null,
                        description: repeater.Rules,
                        modified: repeater.Modified
                    };

                    geoJson.features.push({
                        id: repeater.ID,
                        type: "Feature",
                        properties: properties,
                        geometry: {
                            type: "Point",
                            coordinates: [
                                repeater.Longitude,
                                repeater.Latitude
                            ]
                        }
                    });

                    let ret = this.createGeoJSONCircle([
                        repeater.Longitude,
                        repeater.Latitude
                    ], this.range(repeater.Radius, repeater.HAAT), 32);

                    geoJsonCoverage.features.push({
                        id: repeater.ID,
                        type: "Feature",
                        properties: {id: repeater.ID},
                        geometry: {
                            type: "Polygon",
                            coordinates: [ret]
                        }
                    });
                });

                this.geoJsonSource.data = geoJson;
                this.geoJsonSourceCoverage.data = geoJsonCoverage;
                this.addSources();

                // Restore selected state if there was a selected repeater
                if (selectedRepeaterId) {
                    this.$nextTick(() => {
                        map.setFeatureState(
                            {source: 'repeater-coverage', id: selectedRepeaterId},
                            {selected: true}
                        );
                    });
                }
            },

            navigateToRepeater(id) {
                this.$router.push({
                    name: 'repeater',
                    params: { id: id }
                });
            },

            async fetchSingleRepeater(id) {
                const headers = {};
                if (this.user.authToken) headers.Authorization = `Bearer ${this.user.authToken}`;
                
                const response = await axios.get(`${config.API_LOCATION}/repeater/${id}`, { headers });
                return response.data;
            },

            async loadAllRepeaters() {
                if (!this.loadingAllRepeaters) {
                    this.loadingAllRepeaters = true;
                    try {

                        const allRepeaters = await this.fetchRepeaterData();
                        this.updateMapData(allRepeaters);
                    } finally {
                        this.loadingAllRepeaters = false;
                    }
                }
            },

            checkLoadingComplete() {
                // Hide loading when both style and data are ready
                if (this.styleLoaded && this.initialLoadComplete) {
                    this.dialogs.loading.state = false;
                }
            },
        },

        mounted() {
            useTitle('Repeater Map - myGMRS.com');

            this.initMap();
        }
    }
</script>
